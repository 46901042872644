<template>
  <div>
    <div class="header">
      <div class="header_box content2">
        <div class="title">文秘+新媒体创作数字教学资源平台</div>
        <div class="header_rt_box">
          <div @click="goLogin">登录</div>
          <div @click="goRegister">注册</div>
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="nav_box content2">
        <div class="active">首页</div>
        <div @click="goCenter">资源中心</div>
      </div>
    </div>
    <div class="indexbg">
      <img src="../assets/img/indexbg.png" alt="" />
    </div>
    <div class="guanyu">
      <div class="guanyu_box content2">
        <div class="tt">关于我们</div>
        <div class="heng"></div>
        <div class="text">
          新媒体全栈运营视频教学课程从传播、产品、写作、营销等方面入手，带你掌握最前沿的新媒体运营技能。课程精选盖考拉、严选谷歌、摩拜、知乎等国内外案例，通过翻转课堂、项目制学习、作品集产出等教学服务，帮你迅速成为行业抢手的全栈新媒体人才
        </div>
      </div>
    </div>
    <div class="gongju">
      <div class="gongju_box content2">
        <div class="box">
          <div class="img_box">
            <img class="img1" src="../assets/img/zhineng.png" alt="" />
          </div>
          <div class="tt">智能工具</div>
        </div>
        <div class="box">
          <div class="img_box">
            <img class="img2" src="../assets/img/ziyuan.png" alt="" />
          </div>
          <div class="tt">资源建设</div>
        </div>
        <div class="box">
          <div class="img_box">
            <img class="img3" src="../assets/img/neirong.png" alt="" />
          </div>
          <div class="tt">内容体系</div>
        </div>
        <div class="box">
          <div class="img_box">
            <img class="img4" src="../assets/img/shijian.png" alt="" />
          </div>
          <div class="tt">实践活动</div>
        </div>
      </div>
    </div>
    <div class="ipt_box">
      <div class="box_big">
        <div class="box">
          <img class="img1" src="../assets/img/user5.png" alt="" />
          <input class="input" type="text" placeholder="您如何称呼" />
        </div>
        <div class="box">
          <img class="img2" src="../assets/img/school.png" alt="" />
          <input class="input" type="text" placeholder="您所在的学校" />
        </div>
        <div class="box">
          <img class="img3" src="../assets/img/tel.png" alt="" />
          <input class="input" type="text" placeholder="您的联系方式" />
        </div>
        <div class="btn">立即申请试用</div>
      </div>
    </div>
    <div class="foot">
      <div class="box">
        <div class="top">
          <span>友情链接：文秘专业资源库</span>
          <span>|</span>
          <span>河南经贸职业学校</span>
          <span>|</span>
          <span>国家教学资源库</span>
          <span>|</span>
          <span>mooc</span>
          <span>|</span>
          <span>意见反馈</span>
        </div>
        <div class="bm">中文速录1+x</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    goLogin() {
      this.$router.push('/login')
    },
    goRegister() {
      this.$router.push('/register')
    },
    // 跳转到资源中心
    goCenter() {
      this.$router.push('/center')
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 110px;
  background: #538eff;

  .header_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @font-face {
      font-family: myFirstFont;
      src: url('../assets/FZXKJW.TTF');
    }
    .title {
      font-size: 48px;
      font-family: FZXingKai-S04S;
      color: #ffffff;
      font-family: myFirstFont;
    }
    .header_rt_box {
      display: flex;
      align-items: center;
      color: #ffffff;
      div:nth-child(1) {
        width: 73px;
        height: 37px;
        border: 1px solid #ffffff;
        border-radius: 15px;
        font-size: 19px;
        text-align: center;
        line-height: 37px;
        cursor: pointer;
      }
      div:nth-child(2) {
        width: 73px;
        height: 37px;
        background: #ffffff;
        border-radius: 15px;
        margin-left: 30px;
        font-size: 19px;
        text-align: center;
        line-height: 37px;
        color: #538eff;
        cursor: pointer;
      }
    }
  }
}
.nav {
  width: 100%;
  height: 60px;
  background: #ffffff;

  .nav_box {
    display: flex;
    align-items: center;
    font-size: 20px;
    div {
      height: 58px;
      border-bottom: 2px solid #ffffff;
      padding: 0 4px;
      line-height: 60px;
      cursor: pointer;
    }
    div:nth-child(2) {
      margin-left: 46px;
    }
    .active {
      border-bottom: 2px solid #538eff;
    }
  }
}
.indexbg {
  width: 100%;
  height: 622px;
  img {
    width: 100%;
    height: 100%;
  }
}
.guanyu {
  width: 100%;
  height: 285px;
  background: #ffffff;
  .guanyu_box {
    .tt {
      font-size: 32px;
      text-align: center;
      margin-top: 50px;
    }
    .heng {
      width: 40px;
      height: 6px;
      background: #3399ff;
      margin: 40px auto 60px;
    }
    .text {
      font-size: 16px;
      line-height: 30px;
    }
  }
}
.gongju {
  width: 100%;
  height: 490px;
  background-color: #f1f4f8;
  overflow: hidden;
  .gongju_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box {
      .img_box {
        width: 260px;
        height: 260px;
        background: linear-gradient(90deg, #538eff, #6799f8);
        box-shadow: 0px 0px 13px 0px rgba(127, 127, 127, 0.14);
        position: relative;
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .img1 {
          width: 93px;
          height: 97px;
          display: block;
        }
        .img2 {
          width: 92px;
          height: 112px;
          display: block;
        }
        .img3 {
          width: 92px;
          height: 92px;
          display: block;
        }
        .img4 {
          width: 93px;
          height: 96px;
          display: block;
        }
      }
      .tt {
        font-size: 32px;
        margin-top: 30px;
        text-align: center;
      }
    }
  }
}
.ipt_box {
  width: 100%;
  height: 550px;
  background-color: #fff;
  position: relative;
  .box_big {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 520px;
    transform: translate(-50%, -50%);
    .box {
      width: 520px;
      height: 70px;
      background: #ffffff;
      border: 1px solid #bfbfbf;
      box-shadow: 0px 11px 24px 0px rgba(217, 217, 217, 0.19);
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      padding: 16px;
      .img1 {
        width: 34px;
        height: 36px;
        margin-right: 30px;
      }
      .img2 {
        width: 41px;
        height: 39px;
        margin-right: 23px;
      }
      .img3 {
        width: 29px;
        height: 40px;
        margin-right: 33px;
      }
    }
    .btn {
      width: 520px;
      height: 70px;
      background: #5b92fc;
      border: 1px solid #bfbfbf;
      box-shadow: 0px 11px 24px 0px rgba(217, 217, 217, 0.19);
      font-size: 30px;
      color: #f2f2f2;
      text-align: center;
      line-height: 70px;
    }
  }
  /* 去掉input默认样式 */
  input {
    background: none;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    font-size: 16px;
  }
  input:focus {
    border: none;
  }
  .input input::-webkit-input-placeholder {
    font-size: 16px;
    color: #999999;
  }
}
.foot {
  width: 100%;
  height: 234px;
  background-color: #39404e;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  overflow: hidden;
  span {
    margin: 0 10px;
  }
  .top {
    margin: 54px 0 45px;
  }
}
</style>